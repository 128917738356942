import type { LoyaltyProgramMenu_content$data } from '../__generated__/LoyaltyProgramMenu_content.graphql';
import type { LoyaltyProgramProgress_block$data } from './__generated__/LoyaltyProgramProgress_block.graphql';

type Level = LoyaltyProgramProgress_block$data['loyaltyLevels'] | LoyaltyProgramMenu_content$data['loyaltyLevels'];

export const getPlayerLevelImage = (levels: Level | null, playerLevel: number) => {
  if (levels == null) {
    return null;
  }

  const level = playerLevel - 1;

  if (playerLevel === 0) {
    return levels[0];
  }

  if (level >= 0 && level < levels.length) {
    return levels[level];
  }

  return levels[levels.length - 1];
};
