import { graphql, useFragment } from 'react-relay/hooks';
import type { LoyaltyProgramProgress_block$data } from './parts/__generated__/LoyaltyProgramProgress_block.graphql';
import type { LoyaltyProgramMenu_content$data } from './__generated__/LoyaltyProgramMenu_content.graphql';
import type { useCalculateProgress_player$key } from './__generated__/useCalculateProgress_player.graphql';

const playerFragment = graphql`
  fragment useCalculateProgress_player on Player {
    loyaltyProgram {
      level
      monthlyInitialLevel
      monthlyPoints
    }
  }
`;

type Level = LoyaltyProgramProgress_block$data['loyaltyLevels'] | LoyaltyProgramMenu_content$data['loyaltyLevels'];

export const useCalculateProgress = (playerData: useCalculateProgress_player$key, levels: Level) => {
  const player = useFragment(playerFragment, playerData);

  if (player.loyaltyProgram == null) {
    return {
      maintainPoints: 0,
      requiredValue: 0,
      requiredPoints: 0,
      playerLevel: 0,
    };
  }

  const { level: playerLevel, monthlyInitialLevel, monthlyPoints: totalPoints } = player.loyaltyProgram;

  const currentLevel = levels[playerLevel] ?? levels[0];
  const previousLevel = levels[playerLevel - 1];
  const requiredPoints = currentLevel.minimumPoints;
  const maintainPoints = previousLevel.maintenancePoints;

  return {
    maintainPoints: playerLevel === monthlyInitialLevel ? maintainPoints : 0,
    requiredValue: totalPoints,
    requiredPoints: requiredPoints,
    playerLevel,
  };
};
